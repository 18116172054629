import React from "react";

interface MessageProps {
    message: string;
    username: string;
    profilePicture: string;
    id: string;
}

const Message: React.FC<MessageProps> = ({ message, profilePicture, username, id }) => {
    return (
        <div className="p-1">
            <div className="row">
                <div className="col-lg-auto col-md-12 d-flex align-items-center">
                    {profilePicture ? (
                        <img src={profilePicture} alt="profileImg" width={35} height="auto" />
                    ) : <i className="fa-solid fa-user fa-2x"></i>}
                    <div style={{marginLeft: "12px", position: 'relative', top: '1rem'}}>
                        <span className="text__secondary">{username}</span>
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Message;
