import {Sidebar} from "../../../components/Sidebar";
import React, {useEffect, useState} from "react";
import {createStream, deleteStream, getStreamDetails} from "../../../api/streaming/LiveStreamService";
import {parseJwt} from "../../../Helper/DecodeJWT";
import {StreamStart} from "../components/StreamStart";
import {StreamStarted} from "../components/StreamStarted";
import {WebRTCAdaptor} from "@antmedia/webrtc_adaptor/dist/es";
import {streamHost} from "../../../api/config";
import {onError} from "../../../provider/SweetAlert/SweetAlertProvider";

export const CreateStream = () => {
    const [channelId, setChannelId] = useState("");
    const [stream, setStream] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isStarting, setStarting] = useState(false);

    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [category, setCategory] = useState("");
    const [webRTC, setWebRTC] = useState<any>(null);
    const [streamId, setStreamId] = useState<string>("");
    const [videoSrc, setVideoSrc] = useState<string>("");

    const initializeWebRTCAdaptor = (response: any) => {
        const webRTCAdaptor = new WebRTCAdaptor({
            websocket_url: `wss://${streamHost()}/LiveApp/websocket`,
            mediaConstraints: {
                video: true,
                audio: true
            },
            localVideoElement: document.getElementById('id-of-video-element'),
            dataChannelEnabled: true,
            debug: true,
            localVideoId: 'id-of-video-element',
            callback: function(info: any, obj: any) {
                if (info === "initialized") {
                    setStreamId(response);
                    webRTCAdaptor.publish(response);
                }

                if (info === "data_channel_opened") {
                    setTimeout(() => {
                        localVideoRun();
                        setVideoSrc(`https://amazon.koenig-digital.cloud:5443/LiveApp/streams/${response}.m3u8`)
                    }, 5000)
                }
            }
        });

        setWebRTC(webRTCAdaptor);
    };

    const localVideoRun = () => {
        // @ts-ignore
        if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
            // @ts-ignore
            navigator.userMedia = navigator.mozGetUserMedia || navigator.getUserMedia
            // @ts-ignore
            if (!navigator.userMedia) {
                alert("Please Update or Use Different Browser");
                return
            }
            // @ts-ignore
            navigator.userMedia({
                video: true
            }, (stream: any) => showCam(stream), (err: any) => showErr(err))
            return
        }

        navigator.mediaDevices.getUserMedia({
            video: true
        })
            .then((stream) => showCam(stream))
            .catch((err) => showErr(err))

        const showCam = (stream: any) => {
            let video = document.querySelector("video");
            video!.srcObject = stream;
        }

        const showErr = (err: any) => {
            let message = err.name === "NotFoundError" ? "Please Attach Camera" :
                err.name === "NotAllowedError" ? "Please Grant Permission to Access Camera" : err
            alert(message)
        }
    };

    useEffect(() => {
        setLoading(true);
        requestStreamDetails();

        setInterval(() => {
            if (isStarting) {
                requestStreamDetails();
            }
        }, 30000)

        return () => stopStream();
    }, []);

    useEffect(() => {
        setLoading(true);
        requestStreamDetails();
    }, [isStarting])

    const requestStreamDetails = () => {
        const jwt = parseJwt();

        getStreamDetails(jwt?.sub).then((response: any) => {
            setChannelId(response.data.userAgent);
            setStream(response.data);
            setStarting(true);

            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })
    }

    const startStream = () => {
        createStream(title, description, category).then((response: any) => {
            setStarting(true);

            initializeWebRTCAdaptor(response.data.streamId);
        }).catch((error: any) => console.error(error));
    };


    const stopStream = () => {
        setVideoSrc("");

        const jwt = parseJwt();
        if (webRTC) {
            webRTC.stop(streamId);
        }

        deleteStream(jwt?.sub).then(() => {
            setStarting(false);
            window.location.reload();
        }).catch((error: any) => console.error(error));
    }


    return (
        <Sidebar loading={loading}>
            {isStarting ?
                <StreamStarted videoSrc={videoSrc} stream={stream} channelId={channelId} stopStream={() => stopStream()} /> :
                <StreamStart
                    startStream={() => {
                        if (!description.length || !category.length || !title.length) {
                            onError("Bitte überprüfe deine Eingabe")
                            return;
                        }
                        startStream();
                    }}
                    setDescription={setDescription}
                    setCategory={setCategory}
                    setTitle={setTitle}
                />
            }
        </Sidebar>
    )
}
