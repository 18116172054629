import {Sidebar} from "../../components/Sidebar";
import {LiveChat} from "./components/LiveChat";
import React, {useEffect, useState} from "react";
import {followUser, getFollow, getStreamDetails, unfollowUser} from "../../api/streaming/LiveStreamService";
import {StreamHelper} from "../../Helper/StreamHelper";
import {useParams} from "react-router-dom";
import {parseJwt} from "../../Helper/DecodeJWT";
import LiveChatRework from "./components/LiveChatRework";
import swal from 'sweetalert';

interface OptionsProps {
    rtmpUrl: string | null
}

export const Options: React.FC<OptionsProps> = (props) => {

    const copyToClipboard = async (url: string) => {
        try {
            await navigator.clipboard.writeText(url);
            await swal({
                title: "URL kopiert!",
                text: "Die URL befindet sich in deiner Zwischenablage!",
                icon: "success"
            })
        } catch (err) {
            console.error('Fehler beim Kopieren: ', err);
        }
    };

    return (
        <div className="float-end" style={{ marginRight: "20px" }} onClick={() => copyToClipboard(props.rtmpUrl !== null ? window.location.origin + "/stream/detail/" + extractLastNumberFromUrl(props.rtmpUrl).toString() : "")}>
            <a href="#!" className="btn btn__secondary btn__secondary--big">
                <i className="fa-solid fa-share" style={{ position: 'relative', right: '1rem' }}></i>
                Teilen
            </a>
        </div>
    );
}

function extractLastNumberFromUrl(url: string) {
    const parts = url.split('/');
    const lastPart = parts[parts.length - 1];
    return parseInt(lastPart, 10);
}

function getBaseUrl(url: string) {
    const parsedUrl = new URL(url);
    return parsedUrl.origin;
}


export const SubCategories = ({ category }: {category: any}) => {
    return category.map((category: string) => {
        return (
            <ul className="nav text-nowrap flex-nowrap m-2" style={{overflowX: 'auto', padding: 0}}>
                <li className="nav-item">
                    <a className="nav-link p-1 m-0" aria-current="page" href={"#" + "name"}>
                        <span className="badge badge__secondary__bold" style={{padding: '.75rem', borderRadius: 100}}>
                            {category}
                        </span>
                    </a>
                </li>
            </ul>
        )
    })
}

export const StreamDetail = () => {
    const [channelId, setChannelId] = useState("");
    const [stream, setStream] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isFollowed, setFollowing] = useState<boolean>(false);
    const params = useParams();

    useEffect(() => {
        const id: string = params.id ?? "";
        setLoading(true);

        getStreamDetails(id).then((response: any) => {
            setChannelId(response.data.userAgent);
            setStream(response.data);

            setLoading(false);
        }).catch((error: any) => {
            console.log(error);
        })
    }, []);

    useEffect(() => {
        const id: string = params.id ?? "";

        setInterval(() => {
            getStreamDetails(id).then((response: any) => {
                setStream(response.data);
            }).catch((error: any) => {
                console.log(error);
            })
        }, 30000)

    }, []);

    useEffect(() => {
        getFollow().then((response: any) => {
            response.data.map((follower: any) => {
                if (follower.id == stream.streamId) {
                    setFollowing(true);
                }
            })
        }).catch((error: any) => {
            console.error(error)
        })
    }, [stream])

    const follow = (stream: any) => {
        followUser(stream.streamId).then((response: any) => {
            setFollowing(true);
        }).catch((error: any) => {
            console.error(error);
        })
    }

    const unfollow = (stream: any) => {
        unfollowUser(stream.streamId).then((response: any) => {
            setFollowing(false);
        }).catch((error: any) => {
            console.error(error);
        })
    }

    const RenderFollow = () => {
        const jwt = parseJwt();

        if (stream?.streamId === jwt?.sub) {
            return <></>;
        }

        return !isFollowed ? (
            <button className="btn btn__primary__outline p-3 m-2" disabled={!jwt || stream?.streamId === jwt?.sub} style={{width: '100%'}} onClick={() => follow(stream)}>
                Folgen
            </button>
        ) : (
            <button className="btn btn__primary__outline p-3 m-2" style={{width: '100%'}} onClick={() => unfollow(stream)}>
                Entfolgen
            </button>
        )
    }

    const Profile = ({stream}: {stream: any}) => (
        <div className="pb-5 mt-4">
            <div className="row">
                <div className="col-auto">
                    {(stream?.User.profileImageBase64 === "") ? <i className="fa-solid fa-user fa-4x"></i> : (
                        <img
                            src={stream?.User.profileImageBase64}
                            alt="profileImg"
                            width={50}
                            height="auto"
                            style={{borderRadius: '1000px', position: 'relative', left: '1rem'}}
                        />
                    )}
                </div>
                <div className="col-8">
                    <h5 className="m-2">{stream?.User.first_name} {stream?.User.last_name}</h5>
                    <p className="m-2" style={{position: 'relative', bottom: '.5rem'}}>{stream?.User?.follower} Follower</p>
                </div>
                <div className="col-lg-2 col-md-12">
                    <RenderFollow />
                </div>
            </div>
        </div>
    )

    return (
        <Sidebar loading={loading}>
            <div className="container-fluid row">
                <div className="col-lg-8 col-md-12 p-0">
                    <StreamHelper stream={stream} />

                    <div className="d-lg-none d-block mb-5 mb-lg-0">
                        <LiveChatRework channelId={channelId} />
                    </div>

                    <div style={{paddingLeft: '2rem', paddingTop: '1rem'}}>
                        <h3>{stream?.name}</h3>

                        <Options rtmpUrl={stream?.rtmpURL}/>
                        <SubCategories category={[stream?.category]} />

                        <div className="table-group-divider text__grey mt-4"></div>

                        <p className="p-3 col-lg-8 col-md-12">
                            {stream?.description}
                        </p>

                        <div className="table-group-divider text__grey"></div>

                        <Profile stream={stream} />
                    </div>
                </div>
                <div className="col-lg-4 col-md-12 p-0 d-none d-lg-block">
                    <LiveChatRework channelId={channelId} />
                </div>
            </div>
        </Sidebar>
    )
}
