import React, {useEffect, useState} from "react";
import {closeModal} from "../../../components/Modal/Modal";
import {deleteImage, updateBanner, updateProfileImage} from "../../../api/user/ProfileService";
import {parseJwt} from "../../../Helper/DecodeJWT";
import {onError} from "../../../provider/SweetAlert/SweetAlertProvider";

export const IMAGE_UPLOADER_MODAL_ID = "bannerImageModalId";
export const IMAGE_UPLOADER_MODAL_NAME = "bannerImageModal";

export const ImageUploaderModal = ({ isProfile, setNewBanner, setNewProfile }: { isProfile: boolean, setNewBanner: any, setNewProfile: any }) => {
    const [imagePreview, setImagePreview] = useState("");

    useEffect(() => {
        let isAdvancedUpload = function() {
            let div = document.createElement('div');
            return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
        }();

        let draggableFileArea = document.querySelector(".drag-file-area");
        let dragDropText = document.querySelector(".dynamic-message");
        let fileInput = document.querySelector(".default-file-input");

        // @ts-ignore
        if(isAdvancedUpload) {
            ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach( evt =>
                // @ts-ignore
                draggableFileArea.addEventListener(evt, e => {
                    e.preventDefault();
                    e.stopPropagation();
                })
            );

            ["dragover", "dragenter"].forEach( evt => {
                // @ts-ignore
                draggableFileArea.addEventListener(evt, e => {
                    e.preventDefault();
                    e.stopPropagation();
                    // @ts-ignore
                    dragDropText.innerHTML = 'Datei fallen lassen';
                });
            });

            // @ts-ignore
            fileInput.addEventListener("change", e => {
                // @ts-ignore
                let files = fileInput?.files;
                // @ts-ignore
                getBase64(files[0]);
            });

            // @ts-ignore
            draggableFileArea.addEventListener("drop", e => {
                // @ts-ignore
                let files = e.dataTransfer.files;
                // @ts-ignore
                getBase64(files[0]);
            });
        }
    }, []);

    const getBase64 = (file: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setImagePreview(reader.result as string);
        };
    }

    const submit = () => {
        const jwt = parseJwt();

        if (!isProfile) {
            updateBanner(jwt?.sub, imagePreview).then((response: any) => {
                setNewBanner(imagePreview)
            }).catch((error: any) => {
                onError(error.response.data.error_message);
            })
        } else {
            updateProfileImage(jwt?.sub, imagePreview).then(() => {
                setNewProfile(imagePreview);
            }).catch((error: any) => {
                onError(error.response.data.error_message);
            })
        }

        closeModal(IMAGE_UPLOADER_MODAL_ID);
    }

    const removeImage = () => {
        const jwt = parseJwt();

        deleteImage(jwt?.sub).then(() => {
            setNewProfile(null);
            closeModal(IMAGE_UPLOADER_MODAL_ID)
        }).catch((error: any) => {
            console.error(error);
        })
    }

    return (
        <>
            <div className="modal-body">
                <h5 className="modal-title">{!isProfile ? "Banner" : "Profilbild"} hochladen</h5>

                <div className="text-center mt-3">
                    <img src={imagePreview} width={200} height="auto" style={{ borderRadius: '15px' }} />
                </div>

                <form className="form-container" encType='multipart/form-data'>
                    <div className="upload-files-container">
                        <div className="drag-file-area">
                            <h3 className="dynamic-message">Drag & Drop</h3>
                            <p className="text-center">oder</p>
                            <label className="label text-center">
                                <input type="file" className="default-file-input"/>
                                <span className="browse-files-text">wähle eine Datei</span>
                            </label>
                        </div>
                    </div>
                </form>

                {isProfile && (
                    <div className="text-center">
                        <button type="button" onClick={() => removeImage()} className="btn btn-danger col-8 text-center close mt-4" data-dismiss="modal">
                            Profilbild entfernen
                        </button>
                    </div>
                )}

                <div className="row">
                    <div className="col-6">
                        <button type="button" onClick={() => closeModal(IMAGE_UPLOADER_MODAL_ID)} className="btn btn__secondary col-12 close mt-4" data-dismiss="modal">
                            Abbrechen
                        </button>
                    </div>
                    <div className="col-6">
                        <button type="button" onClick={() => submit()} className="btn btn__primary col-12 close mt-4 upload-button" data-dismiss="modal">
                            Hochladen
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}