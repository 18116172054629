import axios from "axios";
import {root} from "../config";


export async function getAllCategories() {
    return axios({
        url: `${root()}/v1/livestream/category`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}