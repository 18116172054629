import {openModal} from "../Modal/Modal";
import {LOGIN_MODAL_ID} from "../../feature/components/Authentication/Login";
import {REGISTER_MODAL_ID} from "../../feature/components/Authentication/Registration";
import React from "react";

const LoggedOutNav = () => {
    return (
        <div className="ml-2">
            <button className="btn btn__primary m-3" id="registerModal" onClick={(event) => {
                event.preventDefault();
                openModal(REGISTER_MODAL_ID);
            }}>Registrieren</button>
            <button className="btn btn__primary__outline m-3" id="loginModal" onClick={(event) => {
                event.preventDefault();
                openModal(LOGIN_MODAL_ID);
            }}>
                Anmelden
            </button>
        </div>
    )
}

export const LoggedInNav = ({loggedIn, user, profile, isSeller}: {loggedIn: boolean, user: any, profile?: string | null, isSeller: boolean}) => {
    if (!loggedIn && !localStorage.getItem('token')) return <LoggedOutNav />;

    return (
        <div className="ml-2">
            {isSeller && (
                <a href="/stream/create" className="btn btn__primary__outline m-3">
                    <i className="fa fa-users"></i>
                    Live Stream
                </a>
            )}

            <a href="/profile">
                {user?.profileImageBase64 || profile ? (
                    <img
                        src={profile ?? user?.profileImageBase64}
                        alt="profileImg"
                        width={50}
                        className="m-3"
                        height="auto"
                        style={{ borderRadius: '1000px' }}
                    />
                ) : <i className="fa-solid fa-user fa-2x p-3 mt-2 text-black" style={{ position: 'relative', top: '.5rem' }}></i>}
            </a>
        </div>
    )
}