import axios from "axios";
import {root} from "../config";


export async function joinChannel(id: string) {
    return axios({
        url: `${root()}/v1/chat/channels/join?channelId=${id}`,
        method: 'post',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function getMessages(id: string, since: number) {
    return axios({
        url: `${root()}/v1/chat/channels/messages/filter?channelId=${id}&since=${since}`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function sendChatMessage(id: string, message: string) {
    return axios({
        url: `${root()}/v1/chat/channels/messages?channelId=${id}&message=${message}`,
        method: 'post',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function getMattermostUser(mattermostId: string) {
    return axios({
        url: `${root()}/v1/user/chat?mattermostId=${mattermostId}`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}