import {Modal} from "../Modal/Modal";
import React, {useEffect, useState} from "react";
import {Login, LOGIN_MODAL_ID, LOGIN_MODAL_NAME} from "../../feature/components/Authentication/Login";
import {
    REGISTER_MODAL_ID,
    REGISTER_MODAL_NAME,
    Registration
} from "../../feature/components/Authentication/Registration";
import {LoggedInNav} from "./LoggedInNav";
import {getUser} from "../../api/user/UserService";
import {parseJwt} from "../../Helper/DecodeJWT";

export const Navbar = ({setLogin, profile, isSeller}: {setLogin: React.SetStateAction<any>, profile?: string | null, isSeller: boolean}) => {
    const [isLoggedId, setLoggedIn] = useState(false);
    const [user, setUser] = useState(null);

    const read = () => {
        const payload = parseJwt();
        if (!payload) {
            setLoggedIn(false);
            setLogin(false);
            return;
        }

        getUser(payload!.sub).then((response) => {
            setUser(response.data[0]);
            setLoggedIn(true);
            setLogin(true);
        }).catch((error: any) => {
            if (error.response?.status === 401) {
                setLoggedIn(false);
                setLogin(false);
                localStorage.removeItem('token');
            }
        });
    }

    useEffect(() => {
        read();
    }, []);

    return (
        <>
            <nav className="navbar navbar-light bg-light justify-content-between">
                <div className="left-inner-addon input-container">
                </div>
                <form className="form-inline">
                    <LoggedInNav loggedIn={isLoggedId} user={user} profile={profile} isSeller={isSeller} />
                </form>
            </nav>

            <Modal name={REGISTER_MODAL_NAME} id={REGISTER_MODAL_ID}>
                <Registration />
            </Modal>

            <Modal name={LOGIN_MODAL_NAME} id={LOGIN_MODAL_ID}>
                <Login isLogged={() => read()} />
            </Modal>
        </>
    )
}