import {Navbar} from "../../components/Navbar";
import {Table} from "../../components/Table";
import React, {useEffect, useState} from "react";
import {getAllUsers, requestLockUser, requestUnlockUser} from "../../api/user/UserService";
import {onError, successAlert} from "../../provider/SweetAlert/SweetAlertProvider";
import {exportDate} from "../../Helper/DateHelper";
import {Sidebar} from "../../components/Sidebar";
import {Modal, openModal} from "../../components/Modal/Modal";
import {PERMISSIONS_MODAL_ID, PERMISSIONS_MODAL_NAME, PermissionsModal} from "./PermissionsModal";

export const Admin = () => {
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState("");

    useEffect(() => {
       getAllUsers().then((response: any) => {
           setUsers(response.data.User);
       }).catch(() => {
           onError("Es ist ein Fehler aufgetreten");
       });
    }, []);

    const lockUser = (userId: string) => {
        requestLockUser(userId).then((response: any) => {
            successAlert("Der Benutzer wurde erfolgreich gesperrt", "Benutzer gesperrt");
        }).catch((error: any) => onError("Es ist ein Fehler aufgetreten"));
    }

    const unlockUser = (userId: string) => {
        requestUnlockUser(userId).then((response: any) => {
            successAlert("Der Benutzer wurde erfolgreich entsperrt", "Benutzer entsperrt");
        }).catch((error: any) => onError("Es ist ein Fehler aufgetreten"));
    }

    return (
        <>
          <Sidebar>

            <div className="container mt-5">
                <Table
                    title={"Alle Benutzer"}
                    heads={
                        <tr className="text-center">
                            <th className="table__th"></th>
                            <th className="table__th">Benutzername</th>
                            <th className="table__th">Gesperrt</th>
                            <th className="table__th">Passwort aktualisiert</th>
                            <th className="table__th"></th>
                            <th className="table__th"></th>
                        </tr>
                    }
                >
                    {users.map((user: any, index: number) => {
                        return (
                            <tr className="text-center">
                                <td>{index + 1}</td>
                                <td>{user.username}</td>
                                <td>{user.locked ? "Ja" : "Nein"}</td>
                                <td>{exportDate(user.password_changed_at)}</td>
                                <td>
                                    {user.locked ? (
                                        <a href="#!" onClick={() => unlockUser(user.id)} className="btn btn-success btn-sm">Entsperren</a>
                                    ) : (
                                        <a href="#!" onClick={() => lockUser(user.id)} className="btn btn-danger btn-sm">Sperren</a>
                                    )}
                                </td>
                                <td>
                                    <a href="#!" onClick={() => {
                                        setUserId(user.id);
                                        openModal(PERMISSIONS_MODAL_ID);

                                    }} className="btn btn-success btn-sm">Berechtigungen</a>
                                </td>
                            </tr>
                        )
                    })}
                </Table>

                <Modal name={PERMISSIONS_MODAL_NAME} id={PERMISSIONS_MODAL_ID}>
                    <PermissionsModal userId={userId} />
                </Modal>
            </div>
          </Sidebar>
        </>
    )
}
