import React, {useEffect} from "react";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import {streamHost} from "../api/config";

export const StreamHelper = ({stream}: {stream: any}) => {
    const videoRef = React.useRef(null);

    useEffect(() => {
        let player: any = null;

       // @ts-ignore
       player = videojs(videoRef.current, {
           controls: true,
           autoplay: true,
           //fluid: true,
           preload: 'auto',
           sources: [{
               src: `https://${streamHost()}/LiveApp/streams/${stream?.streamId}.m3u8`,
               type: 'application/x-mpegURL'
           }]
       });

        player.landscapeFullscreen({
            fullscreen: {
                enterOnRotate: true,
                alwaysInLandscapeMode: true,
                iOS: true
            }
        });

        return () => {
            if (player) {
                player.dispose();
            }
        };
    }, []);

    return (
        <div data-vjs-player style={{ width: '100%', maxHeight: '25rem' }}>
            <video ref={videoRef} className="video-js vjs-default-skin" />
        </div>
    );
}