import React, {useEffect, useState} from "react";
import {getAllPermissions, getPermissionsByUser, updatePermissions} from "../../api/permission/PermissionService";

export const PERMISSIONS_MODAL_NAME = "permissions_modal_name";
export const PERMISSIONS_MODAL_ID = "permissions_modal_id";

export const PermissionsModal = ({ userId }: { userId: string }) => {
    const [permissions, setPermissions] = useState([]);
    const [allPermissions, setAllPermissions] = useState<[]>([]);

    useEffect(() => {
        getPermissionsByUser(userId).then((response: any) => {
            setPermissions(response.data.Permission);
        }).catch((error) => console.error(error));

        getAllPermissions().then((response: any) => {
            setAllPermissions(response.data.Permission);
        })
    }, [userId])

    const submit = (role: string) => {
        allPermissions.map((permission: any) => {

            if (role === 'admin' && permission.permission_name === 'admin') {
                updatePermissions(userId, [permission.pid])
            } else if (role === 'seller' && permission.permission_name === 'seller') {
                updatePermissions(userId, [permission.pid])
            } else {
                updatePermissions(userId, [permission.pid])
            }
        })
    }

    return (
        <div className="modal-body">
            <h5 className="modal-title">Berechtigungen setzen</h5>

            <div className="mt-5">
                <p className="m-0">Aktuelle Berechtigung:</p>
                {permissions?.map((permission: any) => {
                    return <p>{permission.permission_name}</p>;
                })}
            </div>


            <div className="container row">
                <div className="col-12 col-lg-4">
                    <button type="button" onClick={() => submit('user')} className="btn btn__primary col-12 close mt-4" data-dismiss="modal">
                        Benutzer setzen
                    </button>
                </div>
                <div className="col-12 col-lg-4">
                    <button type="button" onClick={() => submit('seller')} className="btn btn__primary col-12 close mt-4" data-dismiss="modal">
                        Verkäufer setzen
                    </button>
                </div>
                <div className="col-12 col-lg-4">
                    <button type="button" onClick={() => submit('admin')} className="btn btn__primary col-12 close mt-4" data-dismiss="modal">
                        Admin setzen
                    </button>
                </div>
            </div>
        </div>
    )
}