import React, {useState} from "react";
import {activate, login, loginMatterMost} from "../../../api/authentication/Authentication";
import {closeModal} from "../../../components/Modal/Modal";
import {onError} from "../../../provider/SweetAlert/SweetAlertProvider";

export const LOGIN_MODAL_ID = "loginModalId";
export const LOGIN_MODAL_NAME = "loginModal";

export const Login = ({isLogged}: {isLogged: any}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [activatedCode, setActivatedCode] = useState('');
    const [isLocked, setLocked] = useState(false);

    const submit = () => {
        login(username, password).then((response: any) => {
            localStorage.setItem('token', response.data.result.id_token);
            isLogged();

            loginMatterMost(username, password).then((response: any) => {
                localStorage.setItem('mattermostId', response.data.token);

                closeModal(LOGIN_MODAL_ID);
            }).catch((error: any) => {
                console.error(error);
            })

        }).catch((error: any) => {
            error.response.data.errors.map((error: any) => {
                if (error.message === "LOCKED") {
                    setLocked(true);
                }

                if (error.message === "FAILED") {
                    onError("Fehler bei der Anmeldung. Bitte Benutzernamen und Passwort überprüfen")
                }
            })
        })
    }

    const unLockAccount = () => {
        activate(activatedCode).then(() => {
            setLocked(false);
        }).catch((error: any) => {
            console.error(error);
        })
    }

    if (isLocked) {
        return (
            <>
                <div className="modal-body">
                    <h5 className="modal-title">Account freischalten</h5>

                    <div className="form-group mt-3">
                        <label htmlFor="activatedCode">Freischaltungscode</label>
                        <input type="text" className="form-control form__primary" id="activatedCode" aria-describedby="emailHelp"
                               placeholder="Freischaltungscode" onChange={(event: any) => setActivatedCode(event.target.value)} />
                    </div>

                    <button type="button" onClick={() => unLockAccount()} className="btn btn__primary col-12 mt-4">
                        Freischalten
                    </button>
                </div>
            </>
        )
    }

    return (
        <>
            <div className="modal-body">
                <h5 className="modal-title">Anmelden</h5>

                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">Benutzername</label>
                    <input type="email" className="form-control form__primary" id="exampleInputEmail1" aria-describedby="emailHelp"
                           placeholder="Benutzername" onChange={(event: any) => setUsername(event.target.value)} />
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">Passwort</label>
                    <input type="password" className="form-control form__primary" id="exampleInputEmail1" aria-describedby="emailHelp"
                           placeholder="Passwort" onChange={(event: any) => setPassword(event.target.value)} />
                </div>

                <div className="mt-3">
                    <div className="float-end">
                        Passwort vergessen?
                    </div>

                    <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                        <label className="form-check-label" htmlFor="exampleCheck1">Eingeloggt bleiben</label>
                    </div>
                </div>

                <button type="button" onClick={() => submit()} className="btn btn__primary col-12 close mt-4" data-dismiss="modal">
                    Anmelden
                </button>

                <p className="mt-3 text-center text__secondary">Du hast kein Account?
                    <a href="#!" className="text__secondary"> Dann registriere dich hier!</a>
                </p>
            </div>
        </>
    )
}