import ReactDOM from "react-dom/client";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import { Homepage } from "./feature";
import {StreamDetail} from "./feature/StreamDetail";
import {Profile} from "./feature/Profile";
import {CreateStream} from "./feature/Backend/Stream";
import {Follower} from "./feature/Follower";
import {Admin} from "./feature/Admin";
import {Category} from "./feature/Category";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


const Routing = () => {
    return(
        <Router>
            <Routes>
                <Route element={<Homepage />} path="/" />
                <Route element={<Admin />} path="/admin" />
                <Route element={<StreamDetail />} path="/stream/detail/:id" />
                <Route element={<CreateStream />} path="/stream/create" />
                <Route element={<Category />} path="/stream/category/:id" />
                <Route element={<Profile />} path="/profile" />
                <Route element={<Follower />} path="/follower" />
            </Routes>
        </Router>
    )
}

root.render(<Routing />);