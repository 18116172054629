import {Sidebar} from "../../components/Sidebar";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getActivesLiveStreams} from "../../api/streaming/LiveStreamService";
import {StreamCard} from "../components/StreamCard";

export const Category = () => {
    const [streams, setStreams] = useState<any>([]);
    const params = useParams();

    useEffect(() => {
        getActivesLiveStreams().then((response: any) => {
            let categoryStreams: any[] = [];

            response.data.map((categoryStream: any) => {
                if (categoryStream.category === params.id) categoryStreams.push(categoryStream);
            });

            setStreams(categoryStreams);

        }).catch((error: any) => console.error(error))
    }, []);

    return (
        <Sidebar loading={false}>
            <div className="container-fluid row mt-3">
                {streams && streams.length >= 1 && (
                    streams.map((stream: any) => {
                        return(
                            <div className="col-lg-4 col-md-12">
                                <StreamCard stream={stream} />
                            </div>
                        )
                    })
                )}

                {(!streams || streams.length < 1) && (
                    <h4 className="text-center m-5">Es wurden keine Streams gefunden</h4>
                )}
            </div>
        </Sidebar>
    )
}