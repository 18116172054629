
// @ts-ignore
import Demo from "../../assets/images/demo/elegant-european-female-model-posing-purple-background-with-pleased-smile.jpg";
import React, {useEffect, useState} from "react";

export const CurrentLive = ({ streams }: { streams: any}) => {
    const [sortedStreams, setSortedStreams] = useState<any>([]);
    const [mostStream, setMostStream] = useState<any>(null);

    useEffect(() => {
        if (!streams) return;

        let arrayStreams = streams;

        arrayStreams.sort((a: any, b: any) => {
            return b.hlsViewerCount - a.hlsViewerCount;
        });

        let result: any[] = [];

        arrayStreams.map((stream: any, index: number) => {
            if (index < 4) {
                if (index === 0) {
                    setMostStream(stream);
                } else {
                    result.push(stream)
                }
            }
        });

        setSortedStreams(result);
    }, [streams])

    const RenderImage = () => {
        if (mostStream && mostStream?.User?.banner) {
            return (
                <a href={"/stream/detail/"+ mostStream.streamId}>
                    <img src={mostStream?.User?.banner} width="100%" height="450rem" style={{ objectFit: 'cover' }} />
                </a>
            )
        }

        return <img src={Demo} width="100%" height="450rem" style={{ objectFit: 'cover' }} />;
    }

    const Author = () => (
        <div style={{ position: 'absolute', top: '15rem', left: '2.5rem' }}>
            <span className="badge bg-danger">Live</span>
            <h3 className="text-light">{mostStream?.name}</h3>
            <div className="mt-0">
                {mostStream?.User?.profileImageBase64 ? (
                    <img
                        src={mostStream?.User?.profileImageBase64}
                        alt="profileImg"
                        width={125}
                        className="m-3"
                        height="auto"
                        style={{ borderRadius: '1000px' }}
                    />
                ) : <i className="fa-solid fa-user fa-4x m-3 bg-light p-3" style={{ borderRadius: '15px' }}></i>}
                <span className="m-2 text-light">{mostStream?.User?.first_name} {mostStream?.User?.last_name}</span>
            </div>
        </div>
    )

    const SubCategories = () => (
        <div className="float-right" style={{ position: 'absolute', top: 0, right: 0 }}>
            {sortedStreams.map((stream: any) => {
                if (stream?.User?.banner) {
                    return (
                        <div className="card card-body p-0 m-3" style={{ border: '1px solid #fff', borderRadius: '15px' }}>
                            <a href={"/stream/detail/"+ stream.streamId}>
                                <img src={stream?.User?.banner} width="150rem" height="auto" style={{ objectFit: 'cover', border: '1px solid #fff', borderRadius: '15px' }} />
                            </a>
                        </div>
                    )
                }

                return (
                    <div className="card card-body p-0 m-3" style={{ border: '1px solid #fff', borderRadius: '15px' }}>
                        <img src={Demo} width="150rem" height="auto" style={{ objectFit: 'cover', border: '1px solid #fff', borderRadius: '15px' }} />
                    </div>
                )
            })}
        </div>
    )

    return(
        <>
            <div className="card card-body p-0 m-0" style={{ width: '100%', height: 'auto' }}>
                <RenderImage />
                {mostStream && (
                    <Author />
                )}
                {sortedStreams.length >= 1 && (
                    <SubCategories />
                )}
            </div>
        </>
    )
}
