import axios from "axios";
import {root} from "../config";
import {parseJwt} from "../../Helper/DecodeJWT";


export async function createStream(name: string, description: string, category: string) {
    return axios({
        url: `${root()}/v1/livestream/streams`,
        method: 'post',
        data: {
          name: name,
          description: description,
          category: category,
        },
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function deleteStream(id: any) {
    return axios({
        url: `${root()}/v1/livestream/streams/${id}`,
        method: 'delete',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function getActivesLiveStreams() {
    return axios({
        url: `${root()}/v1/livestream/streams`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}

export async function getStreamDetails(streamId: string) {
    return axios({
        url: `${root()}/v1/livestream/streams/${streamId}`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}

export async function followUser(streamId: string) {
    const jwt = parseJwt();

    return axios({
        url: `${root()}/v1/user/follow?followerId=${jwt?.sub}&followingId=${streamId}`,
        method: 'post',
        data: {
            followerId: jwt?.sub,
            followingId: streamId,
        },
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function unfollowUser(streamId: string) {
    const jwt = parseJwt();

    return axios({
        url: `${root()}/v1/user/follow?followerId=${jwt?.sub}&followingId=${streamId}`,
        method: 'delete',
        data: {
            followerId: jwt?.sub,
            followingId: streamId,
        },
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function getFollow() {
    const jwt = parseJwt();

    return axios({
        url: `${root()}/v1/user/follow?userId=${jwt?.sub}`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}