import React, {useState} from "react";
import {register} from "../../../api/authentication/Authentication";
import {LOGIN_MODAL_ID} from "./Login";
import {closeModal, openModal} from "../../../components/Modal/Modal";
import {activateAccount} from "../../../api/user/UserService";
import {onError} from "../../../provider/SweetAlert/SweetAlertProvider";

export const REGISTER_MODAL_ID = "registerModalId";
export const REGISTER_MODAL_NAME = "registerModal";

export const Registration = () => {
    const [isSuccess, setSuccess] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const submit = () => {
        register(userName, firstName, lastName, password).then((response: any) => {
            setSuccess(true);
        }).catch(() => onError("Es ist ein Fehler bei der Registrierung aufgetreten. Bitte überprüfe Benutzername und Passwort"));
    }

    const RegistrationSuccess = () => {
        const [verifyCode, setVerifyCode] = useState("");

        const submit = () => {
            activateAccount(verifyCode).then((response: any) => {
                setSuccess(false);
                closeModal(REGISTER_MODAL_ID);
                openModal(LOGIN_MODAL_ID);
            }).catch(() => onError("Fehler bei der Aktivierung"));
        }

        return (
            <div className="modal-body text-center">
                <i className="fa fa-users fa-4x mb-5"></i>

                <h5 className="text-center">Vielen Dank für deine Registrierung!</h5>
                <p className="text__secondary">
                    Bitte geben Sie den Code in der Email ein um den Account freizuschalten.
                </p>

                <div className="form-group mt-3 mb-3">
                    <label htmlFor="verifyCode">Freischaltungscode</label>
                    <input type="text" className="form-control form__primary" id="verifyCode"
                           onChange={(event: any) => setVerifyCode(event.target.value)} placeholder="Code" />
                </div>

                <a href="#!" className="btn btn__primary w-100 mb-3" onClick={() => submit()}>Account aktivieren</a>
            </div>
        )
    }

    if (isSuccess) {
        return <RegistrationSuccess />;
    }

    return (
        <>
            <div className="modal-body">
                <h5 className="modal-title">Registrieren</h5>

                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">E-Mail</label>
                    <input type="email" className="form-control form__primary" id="exampleInputEmail1" aria-describedby="emailHelp"
                           onChange={(event: any) => setUserName(event.target.value)} placeholder="E-Mail" />
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">Vorname</label>
                    <input type="text" className="form-control form__primary" id="exampleInputEmail1" aria-describedby="emailHelp"
                           onChange={(event: any) => setFirstName(event.target.value)} placeholder="Vorname" />
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">Nachname</label>
                    <input type="text" className="form-control form__primary" id="exampleInputEmail1" aria-describedby="emailHelp"
                           onChange={(event: any) => setLastName(event.target.value)} placeholder="Nachname" />
                </div>
                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">Passwort</label>
                    <input type="password" className="form-control form__primary" id="exampleInputEmail1" aria-describedby="emailHelp"
                           onChange={(event: any) => setPassword(event.target.value)} placeholder="Passwort" />
                </div>

                <button type="button" onClick={() => submit()} className="btn btn__primary col-12 mt-4">
                    Registrieren
                </button>

                <p className="mt-3 text-center text__secondary">Du hast schon einen Account?
                    <a href="#!" className="text__primary" onClick={() => openModal(LOGIN_MODAL_ID)}> Anmelden</a>
                </p>
                <p className="mt-2 text-center text__secondary">Freischaltungscode eingeben?
                    <a href="#!" className="text__primary" onClick={() => setSuccess(true)}> Prozess überspringen</a>
                </p>
            </div>
        </>
    )
}