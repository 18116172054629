export const getTimestamp = () => {
    const current = new Date();
    let lastDate = current.getHours() ;
    let lastMinutes = current.getMinutes() - 30;

    if (lastMinutes < 0) {
        lastDate--;
        let currentLastMinutes = lastMinutes;

        lastMinutes = 59 + currentLastMinutes + 1;
    }

    let time = `${('0' + (lastDate)).slice(-2)}:${('0' + (lastMinutes)).slice(-2)}`
    const dateString = `${current.getFullYear()}-${('0' + (current.getMonth()+1)).slice(-2)}-${('0' + current.getDate()).slice(-2)}`;

    return new Date(`${dateString}T${time}`).getTime();
}
