import swal from "sweetalert";

export const successAlert = (text: string, title: string) => {
    swal(title ?? "Erfolgreich", text, "success");
}

export const onError = (text: string) => {
    swal('Es ist ein Fehler auftreten', text);
}

export const errorAlert = () => {
    swal("Verbindungsfehler", "Bitte überprüfe deine Anfrage!")
}