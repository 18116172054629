import axios from "axios";
import {root} from "../config";

export async function getAllPermissions() {
    return axios({
        url: `${root()}/v1/permissions`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function getPermissionsByUser(userId: string) {
    return axios({
        url: `${root()}/v1/permissions/user?userId=${userId}`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function updatePermissions(userId: string, permissionsIds: number[]) {
    return axios({
        url: `${root()}/v1/permissions/user?userId=${userId}`,
        method: 'put',
        data: {
            new_permission_ids: permissionsIds
        },
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}