
export const Table = ({ title, create, heads, children }: { title: string; create?: any; heads: any; children: any }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <h1 className="row__title">{title}</h1>
                </div>
                <div className="col-lg-6 col-md-12 text-end">
                    {create ?? <></>}
                </div>
            </div>
            <div className="row row--top-20">
                <div className="col-md-12">
                    <div className="table-container">
                        <table className="table" style={{ borderRadius: 15 }}>
                            <thead className="table__thead font font-weight-bold" style={{ borderRadius: 15 }}>
                            {heads}
                            </thead>
                            <tbody className="table__tbody">
                            {children}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}