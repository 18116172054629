
export const auth = () => {
    //return "https://login.koenig-digital.cloud";

    // dev
   // return "{{authUrl}}"
    return "https://login.koenig-digital.cloud";
}

export const root = () => {
    //return "https://livestreambackend.koenig-digital.cloud";

    // dev
   //return "{{backendUrl}}"
    return "https://livestreambackend.koenig-digital.cloud";
}

export const streamHost = () => {
    // stream socket and stream view host
    //return "{{streamUrl}}"
    return "amazon.koenig-digital.cloud:5443"
}