import {Sidebar} from "../components/Sidebar";
import {CurrentLive} from "./components/CurrentLive";
import {HotStreams} from "./components/HotStreams";
import {useEffect, useState} from "react";
import {getAllCategories} from "../api/streaming/CategoryService";
import {getActivesLiveStreams} from "../api/streaming/LiveStreamService";
import {parseJwt} from "../Helper/DecodeJWT";

export const Homepage = () => {
    const [categories, setCategories] = useState<any>([]);
    const [streams, setStreams] = useState<any>([]);

    useEffect(() => {
        getAllCategories().then((response: any) => {
            setCategories(response.data.categories);
        }).catch((error: any) => {
            console.error(error);
        })

        getActivesLiveStreams().then((response: any) => {
            const data = response.data;
            setStreams(data);
        }).catch((error: any) => {
            console.error(error);
        })
    }, []);

    return(
        <>
           <Sidebar>
               <CurrentLive streams={streams} />

               <div className="p-5">
                   <HotStreams categories={categories} streams={streams} />
               </div>
           </Sidebar>
        </>
    )
}