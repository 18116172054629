import {StreamCard} from "./StreamCard";

export const HotStreams = ({ categories, streams }: { categories: string[], streams: any }) => {
    const SubCategories = () => (
        <>
            <ul className="nav text-nowrap flex-nowrap" style={{ overflowX: 'auto', padding: 0 }}>
                {categories.map((category: string) => {
                    return(
                        <li className="nav-item">
                            <a className="nav-link" aria-current="page" href={"#" + category}>
                        <span className="badge badge__primary" style={{ padding: '.75rem', borderRadius: 100 }}>
                            <i className="fa fa-users" style={{ marginRight: '.5rem' }}></i>
                            {category}
                        </span>
                            </a>
                        </li>
                    )
                })}
            </ul>
        </>
    )

    return(
        <>
            <h3>Hot Streams 🔥</h3>
            <SubCategories />

            <div className="container-fluid row mt-3">
                {streams && streams.length >= 1 && (
                    streams.map((stream: any) => {
                        return(
                            <div className="col-lg-4 col-md-12">
                                <StreamCard stream={stream} />
                            </div>
                        )
                    })
                )}

                {(!streams || streams.length < 1) && (
                    <p>Es wurden keine Streams gefunden</p>
                )}
            </div>
        </>
    )
}