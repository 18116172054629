import axios from "axios";
import {root} from "../config";

export async function getUser(id: string ) {
    return axios({
        url: `${root()}/v1/user?userId=${id}`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function updateProfile(id: string, firstName: string, lastName: string, bio: string) {
    return axios({
        url: `${root()}/v1/user?id=${id}&first_name=${firstName}&last_name=${lastName}&bio=${bio}`,
        method: 'put',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function getAllUsers() {
    return axios({
        url: `${root()}/v1/user/all`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function activateAccount(code: string) {
    return axios({
        url: `${root()}/v1/user/activate?key=${code}`,
        method: 'get',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
}

export async function requestLockUser(userId: string) {
    return axios({
        url: `${root()}/v1/user?userId=${userId}`,
        method: 'lock',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function requestUnlockUser(userId: string) {
    return axios({
        url: `${root()}/v1/user?userId=${userId}`,
        method: 'unlock',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}