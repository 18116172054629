import axios from "axios";
import {auth, root} from "../config";

export async function login(username: string, password: string) {
    return axios({
        url: `${auth()}/session`,
        method: 'post',
        data: {
            username: username,
            password: password,
        },
        timeout: 6000,
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        }
    })
}

export async function activate(id: string) {
    return axios({
        url: `${root()}/v1/user/activate`,
        method: 'GET',
        params: {
            key: id,
        },
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
        }
    })
}

export async function loginMatterMost(username: string, password: string) {
    return axios({
        url: `${root()}/v1/chat/login?username=${username}&password=${password}`,
        method: 'post',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
        }
    })
}

export async function register(username: string, firstName: string, lastName: string, password: string) {
    return axios({
        url: `${root()}/v1/user`,
        method: 'post',
        timeout: 6000,
        data: {
            username: username,
            first_name: firstName,
            last_name: lastName,
            password: btoa(password),
        },
        headers: {
            'content-type': 'application/json',
        }
    })
}