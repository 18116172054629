import React, {useEffect, useState} from "react";
import {getAllCategories} from "../../../api/streaming/CategoryService";

export const StreamStart = ({ startStream, setTitle, setDescription, setCategory }: { startStream: any, setTitle: any, setDescription: any, setCategory: any  }) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getAllCategories().then((response: any) => {
            setCategories(response.data.categories);
        }).catch((error: any) => console.error(error))
    }, []);

    return (
        <div className="container-fluid">
            <div className="form-group mt-3">
                <label htmlFor="exampleInputEmail1">Titel</label>
                <input type="text" className="form-control form__message" id="exampleInputEmail1" aria-describedby="emailHelp"
                        onChange={(event) => setTitle(event.target.value)} placeholder="Titel" />
            </div>

            <div className="form-group mt-3">
                <label htmlFor="exampleInputEmail1">Beschreibung</label>
                <input type="text" className="form-control form__message" id="exampleInputEmail1" aria-describedby="emailHelp"
                       onChange={(event) => setDescription(event.target.value)} placeholder="Beschreibung" />
            </div>

            <div className="mt-3 col-12 col-lg-6">
                <select
                    className="form-control form-select-lg"
                    aria-label="Default select example"
                    onChange={(event) => setCategory(event.target.value)}
                >
                    <option selected>Kategorie</option>
                    {categories.map((category: string) => {
                        return <option value={category}>{category}</option>;
                    })}
                </select>
            </div>

            <div className="mt-3 text-end m-4">
                <button className="btn btn__primary" onClick={() => startStream()}>Livestream starten</button>
            </div>
        </div>
    )
}