import React, {FC, useEffect, useState} from "react";
import {getMattermostUser, getMessages, joinChannel, sendChatMessage} from "../../../api/mattermost/MattermostService";
import {getTimestamp} from "../../../Helper/TimeHelper";
import {parseJwt} from "../../../Helper/DecodeJWT";
import {types} from "sass";
import List = types.List;
import Message from "./Message";
import message from "./Message";

interface LiveChatProps {
    channelId: string;
}


type RawMessage = {
    type: string,
    user_id: string,
    message: string
    create_at: number;
}

type MattermostUser = {
    userId: string;
    username: string;
    image: string;
}

const LiveChat: React.FC<LiveChatProps> = ({ channelId }) => {

    const [userCache, setUserCache] = useState<Map<string, MattermostUser>>(new Map());
    const [streamMessages, setStreamMessages] = useState<RawMessage[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [messageCollection, setMessageCollection] = useState<FC[]>([]);


    useEffect(() => {
        if (!channelId.length) return;

        joinChannel(channelId).then(() => {
            reloadMessages();
        }).catch((error: any) => {
            console.error(error);
        })

    }, [channelId]);

    useEffect(() => {
        setInterval(() => {
            reloadMessages();
        }, 20000);
    }, []);

    useEffect(() => {
        const updateCache = async () => {
            await Promise.all(streamMessages.map(item => updateMattermostCache(item.user_id)));
        };
        updateCache();
    }, [streamMessages]);

    const reloadMessages = () => {
        getMessages(channelId, getTimestamp()).then((response: any) => {
            if (response.data && typeof response.data === 'object') {
                // Wandelt das Objekt in ein Array von Nachrichten um
                const messagesArray = Object.values(response.data) as RawMessage[];
                setStreamMessages(messagesArray.sort((a, b) => a.create_at + b.create_at));
            } else {
                console.error('response.data is not an object:', response.data);
                setStreamMessages([]);
            }
        }).catch((error: any) => {
            console.error(error);
        });

    }

    const sendMessage = (event?: any, onClick?: boolean) => {
        if ((!event || event.code === "Enter") || onClick === true) {
            sendChatMessage(channelId, inputValue).then(() => {
                reloadMessages();
            }).catch((error: any) => {
                console.error(error);
            });
            setInputValue("");
        }
    }

    const updateMattermostCache = async (userId: string) => {
        if(userCache.has(userId)) return;
        await getMattermostUser(userId).then((response: any) => {
            const user: MattermostUser = {
                userId: userId,
                image: response.data[0].profileImageBase64,
                username: response.data[0].first_name + " " + response.data[0].last_name
            }
            addNewUserToCache(userId, user)
        }).catch((error: any) => console.error(error));
    }

    const addNewUserToCache = (newUserId: string, newUser: MattermostUser) => {
        const updatedUserCache = new Map(userCache);
        updatedUserCache.set(newUserId, newUser);
        setUserCache(updatedUserCache);
    };

    const convertRawMessages = () => {
        if(streamMessages.length <= 0) return <></>

        return streamMessages.map((item, index) => {
            const user = userCache.get(item.user_id);
            if(user === undefined || user === null) return (<></>)
            return (
                <div key={item.user_id + item.message + index}>
                    <Message message={item.message} username={user.username} profilePicture={user.image} id={item.user_id} />
                </div>
            )
        })
    }


    return (
        <div className="card card-body m-0 p-0" style={{ border: "none", borderTop: '.5px solid', borderRadius: 0 }}>
            <div className="livechat__headline">
                <i className="fa fa-users p-3"></i>
                <span style={{ fontSize: '20px' }}>Live Chat</span>
                <p className="float-end">
                    <i className="fa fa-users p-3"></i>
                </p>
            </div>
            {(parseJwt()) && (
                <div className="livechat__chat" id="live-chat">
                    {convertRawMessages()}
                </div>
            )}
            <div className="livechat__message">
                {(!parseJwt()) ? (
                    <p className="text-center">Bitte melde dich an um den Chat zu benutzen</p>
                ) : (
                    <div className="right-inner-addon">
                        <i className="fa-regular fa-paper-plane" style={{ cursor: 'pointer' }} onClick={() => sendMessage(null, true)}></i>
                        <input type="text"
                               className="form-control form__message"
                               width="50%"
                               value={inputValue}
                               onChange={(event: any) => setInputValue(event.target.value)}
                               onKeyPress={(event: any) => sendMessage(event)}
                               placeholder="Nachricht senden"/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LiveChat;
