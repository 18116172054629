import {Sidebar} from "../../components/Sidebar";
// @ts-ignore
import Demo from "../../assets/images/demo/elegant-european-female-model-posing-purple-background-with-pleased-smile.jpg";
import React, {useEffect, useState} from "react";
import {getUser, updateProfile} from "../../api/user/UserService";
import {parseJwt} from "../../Helper/DecodeJWT";
import {IMAGE_UPLOADER_MODAL_ID, IMAGE_UPLOADER_MODAL_NAME, ImageUploaderModal} from "./components/ImageUploaderModal";
import {Modal, openModal} from "../../components/Modal/Modal";
import {successAlert} from "../../provider/SweetAlert/SweetAlertProvider";

export const Profile = () => {
    const [user, setUser] = useState<any>(null);
    const [aboutTab, setAboutTab] = useState<boolean>(false);

    const [banner, setBanner] = useState<string>("");
    const [profile, setProfile] = useState<string|null>(null);
    const [isProfile, setIsProfile] = useState<boolean>(false);

    useEffect(() => {
        const payload = parseJwt();
        if (!payload) return;

        getUser(payload!.sub).then((response: any) => {
            const responseUser = response.data[0];
            setUser(responseUser);

            setBanner(responseUser?.banner);
            setProfile(responseUser.profileImageBase64);
        }).catch((error: any) => {
            console.error(error);
        })
    }, []);

    const RenderAboutTab = () => {
        const [firstName, setFirstName] = useState<string>("");
        const [lastName, setLastName] = useState<string>("");
        const [bio, setBio] = useState<string>("");

        useEffect(() => {
            setBio(user?.bio);
            setFirstName(user?.first_name);
            setLastName(user?.last_name);
        }, [user]);

        const submit = (event: any) => {
            event.preventDefault();
            const payload = parseJwt();

            updateProfile(payload!.sub, firstName, lastName, bio).then(() => {
                successAlert("Dein Profil wurde erfolgreich aktualisert", "Profil aktualisiert");
            }).catch((error: any) => {
                console.error(error);
            })
        }

        return (
            <div className="profile__content container">
                <p className="mt-3">Persönliche Informationen</p>

                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">Passwort</label>
                    <input type="password" className="form-control form__message" id="exampleInputEmail1" aria-describedby="emailHelp"
                           placeholder="Passwort" />
                </div>

                <div className="float-end">
                    <button className="btn btn__primary m-3" id="registerModal" onClick={(event) => submit(event)}>Speichern</button>
                </div>
            </div>
        )
    }

    const RenderStreamTab = () => {
        const [firstName, setFirstName] = useState<string>("");
        const [lastName, setLastName] = useState<string>("");
        const [bio, setBio] = useState<string>("");

        useEffect(() => {
            setBio(user?.bio);
            setFirstName(user?.first_name);
            setLastName(user?.last_name);
        }, [user]);

        const submit = (event: any) => {
            event.preventDefault();
            const payload = parseJwt();

            updateProfile(payload!.sub, firstName, lastName, bio).then(() => {
                successAlert("Dein Profil wurde erfolgreich aktualisert", "Profil aktualisiert");
            }).catch((error: any) => {
                console.error(error);
            })
        }

        return (
            <div className="profile__content container">
                <p>Allgemein</p>

                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">Nickname</label>
                    <input type="text" className="form-control form__message" id="exampleInputEmail1" aria-describedby="emailHelp"
                           value={lastName} onChange={(event) => setLastName(event.target.value)} placeholder="Nickname" />
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">Benutzername</label>
                    <input type="text" className="form-control form__message" id="exampleInputEmail1" aria-describedby="emailHelp"
                           value={firstName} onChange={(event) => setFirstName(event.target.value)} placeholder="Benutzername" />
                </div>

                <div className="form-group mt-3">
                    <label htmlFor="exampleInputEmail1">Rechtliche Informationen</label>
                    <textarea rows={3} className="form-control form__message" id="exampleInputEmail1" aria-describedby="emailHelp"
                              value={bio} onChange={(event) => setBio(event.target.value)} placeholder="Rechtliche Informationen" />
                </div>

                <div className="float-end">
                    <button className="btn btn__primary m-3" id="registerModal" onClick={(event) => submit(event)}>Speichern</button>
                </div>
            </div>
        )
    }

    return (
        <Sidebar profile={profile}>
            <div className="p-0 m-0">
                <img src={banner} width="100%" height="250rem" style={{ objectFit: 'cover' }} />

                <div className="profile__avatar">
                    <div className="row">
                        <div className="col-12 col-lg-2 text-center">
                            {user?.profileImageBase64 ? (
                                <img
                                    src={user?.profileImageBase64}
                                    alt="profileImg"
                                    width={125}
                                    className="m-3"
                                    height="auto"
                                    style={{ borderRadius: '1000px' }}
                                />
                            ) : <i className="fa-solid fa-user fa-4x m-3 bg-light p-2" style={{ borderRadius: '15px' }}></i>}
                        </div>
                        <div className="col-12 col-lg-8" style={{ position: 'relative', top: '4rem', right: '1rem' }}>
                            <h4 className="text-uppercase m-0">{user?.first_name} {user?.last_name}</h4>
                            <p className="m-0">{user?.follower} followers</p>
                        </div>
                        <div className="col-12 col-lg-2" style={{ position: 'relative', top: '4rem', right: '1rem' }}>
                            {aboutTab ? (
                                <a href="#!" onClick={() => {
                                    setIsProfile(true);
                                    openModal(IMAGE_UPLOADER_MODAL_ID);
                                }} className="btn btn__primary btn__secondary--big z-1">
                                    Profilbild
                                </a>) : (
                                <a href="#!" onClick={() => {
                                    setIsProfile(false);
                                    openModal(IMAGE_UPLOADER_MODAL_ID)
                                }} className="btn btn__primary btn__secondary--big z-1 m-2">
                                    Banner
                                </a>
                            )}
                        </div>
                    </div>
                </div>

                <div className="container mt-5">
                    <p>{user?.bio}</p>

                    <div className="table-group-divider text__grey mt-4"></div>

                    <div className="mt-3">
                        <a href="#!" onClick={() => setAboutTab(false)} className={`btn ${aboutTab ? 'text-secondary' : 'btn__primary__no-curved'}`} style={{ paddingLeft: '1rem' }}>Stream</a>
                        <a href="#!" onClick={() => setAboutTab(true)} className={`btn ${!aboutTab ? 'text-secondary' : 'btn__primary__no-curved'}`} style={{ paddingLeft: '1rem' }}>About</a>
                    </div>

                    <div className="mt-5">
                        {aboutTab ? <RenderAboutTab /> : <RenderStreamTab />}
                    </div>
                </div>

                <Modal name={IMAGE_UPLOADER_MODAL_NAME} id={IMAGE_UPLOADER_MODAL_ID}>
                    <ImageUploaderModal
                        isProfile={isProfile}
                        setNewBanner={(banner: string) => setBanner(banner)}
                        setNewProfile={(profile: string) => setProfile(profile)}
                    />
                </Modal>

            </div>
        </Sidebar>
    )
}