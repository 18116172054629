// @ts-ignore
import Demo from "../../assets/images/demo/elegant-european-female-model-posing-purple-background-with-pleased-smile.jpg";
import React from "react";

export const StreamCard = ({stream}: {stream: any}) => {
    const SubCategories = () => (
        <ul className="nav text-nowrap flex-nowrap m-0 p-0" style={{ overflowX: 'auto', padding: 0 }}>
            <li className="nav-item">
                <a className="nav-link m-1 p-0" aria-current="page" href={"#" + "name"}>
                    <span className="badge bg-secondary">
                        {stream.category}
                    </span>
                </a>
            </li>
        </ul>
    )

    console.log(stream);

    return (
        <a href={"/stream/detail/"+ stream.streamId} className="card card-body m-0 p-0 mt-3" style={{ borderBottomLeftRadius: 15, borderBottomRightRadius: 15 }}>

            <div style={{ position: 'absolute', top: '.5rem', left: '.5rem' }}>
                <span className="badge bg-dark">
                    <span className="badge bg-danger" style={{ position: 'relative', right: '.4rem' }}>
                        Live
                    </span>

                    <span style={{ position: "relative", top: '.1rem', left: '.25rem' }}>
                        <i className="fa fa-users" style={{ position: 'relative', right: '.25rem' }}></i>
                        {stream?.hlsViewerCount}
                    </span>
                </span>
            </div>

            <img src={stream?.User.banner ?? Demo} width="100%" height="100rem" style={{ objectFit: 'cover' }} />

            <div className="container-fluid mt-2">
                <p className="m-0">{stream?.name}</p>
                <SubCategories />

                <div className="mt-2 mb-2">
                    {stream.User?.profileImageBase64 ? (
                        <img
                            src={stream?.User.profileImageBase64}
                            alt="profileImg"
                            width={25}
                            height="auto"
                            style={{ borderRadius: '1000px' }}
                        />
                    ) : <i className="fa-solid fa-user fa-2x m-3"></i>}

                    <span className="m-2">{stream?.User.first_name} {stream?.User.last_name}</span>
                </div>
            </div>
        </a>
    )
}