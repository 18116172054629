import {useEffect, useState} from "react";
import {Navbar} from "../Navbar";
import {getAllCategories} from "../../api/streaming/CategoryService";

// @ts-ignore
import Logo from "../../assets/images/logo.png";
import {getAllPermissions, getPermissionsByUser} from "../../api/permission/PermissionService";
import {parseJwt} from "../../Helper/DecodeJWT";

const SELLER = "seller";
const ADMIN = "admin";

export const Sidebar = ({ children, loading, profile }: { children: React.ReactNode, loading?: boolean, profile?: string | null }) => {
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [categories, setCategories] = useState<any>([]);
    const [isClosed, setClosed] = useState<boolean>(true);
    const [isAdmin, setAdmin] = useState<boolean>(false);
    const [isSeller, setSeller] = useState<boolean>(false);

    useEffect(() => {
        getAllCategories().then((response: any) => {
            setCategories(response.data.categories);
        }).catch((error: any) => {
            console.error(error);
        })
    }, [])

    useEffect(() => {
        const arrows = document.querySelectorAll(".arrow");

        arrows.forEach((arrow) => {
            arrow.addEventListener("click", (e) => {
                // @ts-ignore
                const arrowParent = e!.target!.closest(".arrow").parentElement.parentElement;
                arrowParent.classList.toggle("showMenu");
            });
        });

        const sidebar = document.querySelector(".sidebar");
        const sidebarBtn = document.querySelector(".burger-menu");

        sidebar!.classList.toggle("close");

        sidebarBtn!.addEventListener("click", () => {
            sidebar!.classList.toggle("close");
        });
    }, []);

    useEffect(() => {
        if (loggedIn) {
            const jwt = parseJwt();

            getPermissionsByUser(jwt?.sub).then((response: any) => {
                response.data.Permission?.map((permission: any) => {
                    if (permission.permission_name === ADMIN) {
                        setAdmin(true);
                        setSeller(true);
                    }

                    if (permission.permission_name === SELLER) {
                        setSeller(true);
                    }
                })
            })
        }
    }, [loggedIn]);

    return(
        <>
            <div className="sidebar">
                <div className="logo-details">

                    {!isClosed ?
                        (<img src={Logo} width={250} height="auto" className="p-3 mt-4" />) :
                        (<h3 className="text__primary p-3 mt-4">FN</h3>)
                    }
                </div>
                <ul className="nav-links">
                    <li className="sidebar__active">
                        <a href="/">
                            <i className="fa-solid fa-compass sidebar__active__color"></i>
                            <span className="link_name sidebar__active__color">Entdecken</span>
                        </a>
                        <ul className="sub-menu blank">
                            <li><a className="link_name" href="/">Entdecken</a></li>
                        </ul>
                    </li>
                    <li>
                        <div className="icon-link">
                            <a href="#">
                                <i className="fa-solid fa-border-all"></i>
                                <span className="link_name">Kategorien</span>
                            </a>
                            <i className="fa-solid fa-arrow-left arrow"></i>
                        </div>
                        <ul className="sub-menu">
                            <li><a className="link_name" href="#">Kategorie</a></li>
                            {categories.map((category: string) => (
                                <li><a href={`/stream/category/${category}`}>{category}</a></li>
                            ))}
                        </ul>
                    </li>
                    {loggedIn && (
                        <>
                            <li>
                                <div className="icon-link">
                                    <a href="/follower">
                                        <i className="fa-regular fa-user"></i>
                                        <span className="link_name">Gefolgt</span>
                                    </a>
                                    <ul className="sub-menu blank">
                                        <li><a className="link_name" href="/follower">Gefolgt</a></li>
                                    </ul>
                                </div>
                            </li>
                            {isSeller && (
                                <li>
                                    <a href="/stream/create">
                                        <i className="fa-solid fa-video"></i>
                                        <span className="link_name">Stream starten</span>
                                    </a>
                                    <ul className="sub-menu blank">
                                        <li><a className="link_name" href="/stream/create">Stream starten</a></li>
                                    </ul>
                                </li>
                            )}
                            <li className="mt-5">
                                <a href="/profile">
                                    <i className="fa-regular fa-user"></i>
                                    <span className="link_name">Mein Konto</span>
                                </a>
                                <ul className="sub-menu blank">
                                    <li><a className="link_name" href="/profile">Mein Konto</a></li>
                                </ul>
                            </li>
                            {isAdmin && (
                                <li>
                                    <a href="/admin">
                                        <i className="fa-solid fa-user"></i>
                                        <span className="link_name">Administration</span>
                                    </a>
                                    <ul className="sub-menu blank">
                                        <li><a className="link_name" href="/admin">Administration</a></li>
                                    </ul>
                                </li>
                            )}
                            <li>
                                <a href="#" onClick={() => {
                                    localStorage.clear();
                                    window.location.href = "/";
                                }}>
                                    <i className="fa-solid fa-arrow-right-from-bracket sidebar__logout"></i>
                                    <span className="link_name sidebar__logout">Logout</span>
                                </a>
                            </li>
                        </>
                    )}
                </ul>
            </div>
            <section className="home-section">
                <i
                    className="burger-menu fa-solid fa-bars text__secondary"
                    style={{ position: 'absolute', top: '2.25rem', zIndex: 1, width: '1rem', height: '1rem' }}
                    onClick={() => {
                        (isClosed) ? setClosed(false) : setClosed(true);
                    }}
                ></i>
                <Navbar setLogin={setLoggedIn} profile={profile} isSeller={isSeller} />

                <div className="home-content">
                    {(!loading) && (children)}
                </div>
            </section>
        </>
    )
}