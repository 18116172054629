import axios from "axios";
import {root} from "../config";

export async function updateBanner(id: string, image: string) {
    return axios({
        url: `${root()}/v1/livestream/image?userId=${id}`,
        method: 'post',
        data: {
            image: image,
        },
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function updateProfileImage(id: string, image: string) {
    return axios({
        url: `${root()}/v1/user/image?userId=${id}`,
        method: 'post',
        data: {
            image: image,
        },
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}

export async function deleteImage(id: string) {
    return axios({
        url: `${root()}/v1/user/image?userId=${id}`,
        method: 'delete',
        timeout: 6000,
        headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
        }
    })
}