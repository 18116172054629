import {Sidebar} from "../../components/Sidebar";
import {Table} from "../../components/Table";
import React, {useEffect, useState} from "react";
import {getFollow} from "../../api/streaming/LiveStreamService";

export const Follower = () => {
    const [followers, setFollowers] = useState<any>([]);

    useEffect(() => {
        getFollow().then((response: any) => {
            setFollowers(response.data);
        }).catch((error: any) => console.error(error))
    }, []);

    const RenderImage = ({image}: {image: any}) => {
        return image ? (
            <img
                src={image}
                alt="profileImg"
                width={50}
                className="m-3"
                height="auto"
                style={{ borderRadius: '1000px' }}
            />
        ) : <i className="fa-solid fa-user fa-2x text-black"></i>
    }

    return (
        <Sidebar loading={false}>
            <div className="container-fluid row">
                <div className="col-12 col-lg-10 m-auto mt-4">
                    <div className="card card-body shadow-lg p-4">

                        <Table
                            title={"Alle Follower"}
                            heads={
                                <tr className="text-center">
                                    <th className="table__th"></th>
                                    <th className="table__th"></th>
                                    <th className="table__th">Name</th>
                                    <th className="table__th">Gefolgt am</th>
                                </tr>
                            }
                        >
                            {followers?.map((follower: any, index: number) => {
                                return (
                                    <tr className="text-center">
                                        <td>{index + 1}</td>
                                        <td><RenderImage image={follower.profileImageBase64} /></td>
                                        <td>{follower.first_name} {follower.last_name}</td>
                                        <td>todo..</td>
                                    </tr>
                                )
                            })}
                        </Table>

                        {followers?.length < 1 && (
                            <p className="text-center mr-2">Du hast aktuell keine Follower</p>
                        )}
                    </div>
                </div>
            </div>
        </Sidebar>
    )
}