import React, {useEffect} from "react";


export const closeModal = (id: string) => {
    const myModal = document.getElementById(id);

    if (!myModal) return;
    myModal!.style.display = 'none';
}

export const openModal = (id: string) => {
    const myModal = document.getElementById(id);

    if (!myModal) return;
    myModal!.style.display = 'block';
}

export const Modal = ({ name, children, id }: { name: string, children: React.ReactNode, id: string }) => {
    useEffect(() => {
        if (localStorage.getItem('token')) return;

        function showModal() {
            const myModal = document.getElementById(id);

            if (!myModal) return;
            myModal!.style.display = 'block';
        }

        function hideModal() {
            const myModal = document.getElementById(id);

            if (!myModal) return;
            myModal!.style.display = 'none';
        }

        const triggerButton = document.getElementById(name);

        if (!triggerButton) return;

        triggerButton!.addEventListener('click', showModal);

        const closeButton = document.querySelector('.close');
        closeButton!.addEventListener('click', hideModal);

        window.addEventListener('click', function(event) {
            if (event.target === document.getElementById(id)) {
                hideModal();
            }
        });
    }, []);

    return(
        <>
            <div className="modal" id={id}>
                <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}