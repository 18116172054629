
export const exportDate = (datetime: any) => {
    let date = new Date(datetime);

    return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
}

export const exportDateTime = (datetime: any) => {
    let date = new Date(datetime);

    return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()} Uhr`;
}