import React from "react";
import {Options, SubCategories} from "../../StreamDetail";
import LiveChatRework from "../../StreamDetail/components/LiveChatRework";
import {StreamHelper} from "../../../Helper/StreamHelper";

export const StreamStarted = ({ stream, channelId, stopStream, videoSrc }: { videoSrc: string, stream: any, channelId: any, stopStream: any }) => {


    const RenderStream = () => {
        if (videoSrc === "") {
            return (
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }

        return <video
            id="localVideo"
            controls
            autoPlay
            muted
            style={{
                width: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
            }}
        ></video>;
    }

    return(
        <div className="container-fluid row">
            <div className="mb-4 p-3">
                <div className="row">
                    <div className="col-auto">
                        <h3>Mein Live Stream:</h3>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn__primary" onClick={() => stopStream()}>
                            Livestream stoppen
                        </button>
                    </div>
                    <div className="col-auto">
                        <span className="badge bg-danger p-2" style={{ position: 'relative', top: '.3rem' }}>
                            <span style={{ position: "relative", top: '.1rem', left: '.25rem' }}>
                                <i className="fa fa-users" style={{ position: 'relative', right: '.25rem' }}></i>
                                {stream?.hlsViewerCount}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-lg-8 col-md-12 p-0">
                <RenderStream />

                <div className="d-lg-none d-block mb-5 mb-lg-0">
                    <LiveChatRework channelId={channelId} />
                </div>

                <div style={{paddingLeft: '2rem', paddingTop: '1rem'}}>
                    <h3>{stream?.name}</h3>
                    <Options rtmpUrl={stream?.rtmpURL}/>
                    <SubCategories category={[stream?.category]} />

                    <div className="table-group-divider text__grey mt-4"></div>

                    <p className="p-3 col-lg-8 col-md-12">
                        {stream?.description}
                    </p>

                    <div className="table-group-divider text__grey mt-4"></div>

                    <div className="p-3">
                        <p className="m-0 fw-semibold text-decoration-underline">RtmpUrl:</p>
                        <p>{stream?.rtmpURL}</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 p-0 d-none d-lg-block">
                <LiveChatRework channelId={channelId} />
            </div>
        </div>
    )
}
